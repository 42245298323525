import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBannerFisheries'
import Footer from "../components/App/Footer"
//import TestimonialsStyleOne from '../components/Testimonials/TestimonialsStyleOne'
import FisheriesDetail from '../components/Testimonials/trading'

const Fisheries = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Fisheries" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Fisheries" 
            />
            {/*<TestimonialsStyleOne />*/}
            <FisheriesDetail />
            <Footer />
        </Layout>
    );
}

export default Fisheries